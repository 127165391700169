/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { DirectionalStack } from "../../DesignSystem/Containers";
import { CancelTokenSource } from "axios";
import { FileInfo, NewFileInfo } from "Api";
import { ModalButton, ModalFooter } from "baseui/modal";
import { Table } from "baseui/table-semantic";
import { Button } from "baseui/button";
import Alert from "baseui/icon/alert";
import Check from "baseui/icon/check";
import { FilePath } from "../FileUpload";
import { ObservableFileUploader } from "../../Shared/ObservableFileUploader";
import { gql, useLazyQuery } from "@apollo/client";
import GetFiles from "../../Api/Gql/GetFiles";

export const AddFileModal = ({
  onBack,
  onFinish,
}: {
  onBack: () => void;
  onFinish: () => void;
}): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = React.useState<NewFileInfo[]>([]);
  const [isConfirmation, setIsConfirmation] = React.useState(false);
  const [successfulUploads, setSuccessfulFileUploads] = React.useState<
    FileInfo[]
  >([]);
  const [failedUploads, setFailedUploads] = React.useState<NewFileInfo[]>([]);

  const handleNext = (): void => {
    setIsConfirmation(true);
  };

  const handleBack = (): void => {
    if (isConfirmation) {
      setIsConfirmation(false);
    } else {
      onBack();
    }
  };

  const onRemoveFile = (fileToRemove: NewFileInfo): void => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToRemove)
    );
  };

  const getFileExtension = (filename): string => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const tableData: (string | JSX.Element)[][] = selectedFiles.map((file) => [
    file.name,
    getFileExtension(file.name),
    successfulUploads?.some((uploadInfo) => uploadInfo.id == file.id) ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        Complete <Check size={28} color="green" />
      </div>
    ) : failedUploads?.some((error) => error.id == file.id) ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        Upload Failed <Alert size={22} color="red" />
      </div>
    ) : (
      <div style={{ display: "flex", alignItems: "center" }}>
        In Progress...
      </div>
    ),
    successfulUploads?.some(
      (uploadInfo) =>
        uploadInfo.id == file.id && uploadInfo.aiDetectType != "Undetected"
    ) ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        Complete <Check size={28} color="green" />
      </div>
    ) : (
      <div style={{ display: "flex", alignItems: "center" }}>
        Not Detected <Alert size={22} color="red" />
      </div>
    ),
    <Button
      key={`remove-${file.name}`}
      onClick={() => onRemoveFile(file)}
      kind={"primary"}
      size={"compact"}
    >
      {"Remove"}
    </Button>,
  ]);

  const successfulUploadsTableData: (string | JSX.Element)[][] =
    selectedFiles.map((file) => [
      successfulUploads?.some((uploadInfo) => uploadInfo.id == file.id) && (
        <div style={{ display: "flex", alignItems: "center" }}>{file.name}</div>
      ),
      successfulUploads?.some((uploadInfo) => uploadInfo.id == file.id) &&
        getFileExtension(file.name),
    ]);

  const [getFileInfos] = useLazyQuery(gql(GetFiles), {
    fetchPolicy: "network-only",
    onCompleted: (fileData) => {
      const fileInfos = fileData?.getFiles as FileInfo[];
      setSuccessfulFileUploads((prevFiles) => [...prevFiles, ...fileInfos]);
    },
  });

  const handleUploadCompleted = React.useCallback(
    (successfulUploads: NewFileInfo[], failedUploads: NewFileInfo[]) => {
      if (successfulUploads) {
        const succesfulUploadIds = successfulUploads.map((value) => value.id);
        getFileInfos({
          variables: { ids: succesfulUploadIds },
        });
      }
      if (failedUploads) {
        setFailedUploads(failedUploads);
      }
    },
    [getFileInfos]
  );

  const handleUploadStart = React.useCallback(
    (_cancelToken: CancelTokenSource, filePathsToUpload: FilePath[]) => {
      setSelectedFiles((prevFiles) => [
        ...prevFiles,
        ...filePathsToUpload.map((filePath) => filePath.file),
      ]);
    },
    []
  );

  const renderContent = (): JSX.Element => {
    if (isConfirmation) {
      return (
        <div>
          <h3>Confirmation</h3>
          <br></br>
          <>
            <h3>These files were successfully uploaded to Dexter:</h3>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <Table
                columns={["Filename", "Type"]}
                data={successfulUploadsTableData}
              />
            </div>
          </>
          <p>Proceed to populating Transform Engine with these files?</p>
        </div>
      );
    }

    return (
      <DirectionalStack>
        {`Choose files to upload. Each file will be used to populate the transform engine.`}
        <ObservableFileUploader
          onUploadStart={handleUploadStart}
          onUploadComplete={handleUploadCompleted}
        />
        {selectedFiles.length > 0 && (
          <>
            <h3>Selected Files:</h3>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <Table
                columns={[
                  "Filename",
                  "Type",
                  "Upload Status",
                  "AI Scan Status",
                  "",
                ]}
                data={tableData}
              />
            </div>
          </>
        )}
      </DirectionalStack>
    );
  };

  return (
    <div>
      {renderContent()}
      <div style={{ position: "relative", top: "35px" }}>
        <ModalFooter>
          <ModalButton kind="tertiary" onClick={handleBack}>
            Back
          </ModalButton>
          {!isConfirmation && (
            <ModalButton
              kind="tertiary"
              onClick={handleNext}
              disabled={
                successfulUploads.length === 0 || selectedFiles.length === 0
              }
            >
              Next
            </ModalButton>
          )}
          {isConfirmation && (
            <ModalButton kind="tertiary" onClick={onFinish}>
              Finish
            </ModalButton>
          )}
        </ModalFooter>
      </div>
    </div>
  );
};
