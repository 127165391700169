/**
 * Copyright SimVentions, Inc. Usage, distribution, transferal, and licensing
 * of this source code is protected under SBIR law as described in DFARS 252.227-7018.
 *
 * SBIR data rights fully described in the README.md file in the top level directory of this project.
 */
import * as React from "react";
import { FileUploader } from "baseui/file-uploader";
import axios, { CancelTokenSource } from "axios";
import { notify, notifyBatchResponse } from "./Notify";
import {
  FileError,
  FileUploadManifest,
  FileUploadResponse,
  NewFileInfo,
} from "Api";
import { getParentPath, matchByName } from "../Api/FileInfo";
import { AxiosContext } from "../Utils/AuthContext";
import { FilePath } from "../Model/FileUpload";
import { createUUID } from "../Utils/Types";

export interface ObservableFileUploaderProps {
  onUploadStart: (
    cancelToken: CancelTokenSource,
    uploadedFilePaths: FilePath[]
  ) => void;
  onUploadComplete: (
    successfulUploads: NewFileInfo[],
    failedUploads: NewFileInfo[]
  ) => void;
}
const getAllFailedMessage = (failedElements?: FileError[]): string => {
  const errorMessages = failedElements.map((element) => element.uploadError);
  const uniqueErrorMessages = [...new Set(errorMessages)].join(", ");
  return `Failed to upload all files because of ${uniqueErrorMessages}.`;
};

const getPartialSuccessMessage = (
  failedElements: FileError[],
  successfulElements?: string[],
  duplicateElements?: string[]
): string => {
  const failedFileNames = failedElements
    .map((element) => element.fileName)
    .join(", ");

  if (duplicateElements) {
    return `Added ${successfulElements.length} files; ${duplicateElements.length} were previously uploaded. Could not upload ${failedFileNames}.`;
  } else {
    return `Added ${successfulElements.length} files. Could not upload ${failedFileNames}.`;
  }
};

const getSingleSuccessMessage = (successfulFile: string): string => {
  return `Added new file ${successfulFile}.`;
};

const getFewSuccessMessage = (successfulElements: string[]): string => {
  const successfulFileNames = successfulElements
    .map((element) => element)
    .join(", ");
  return `Added new files ${successfulFileNames}.`;
};

const getManySuccessMessage = (successfulFiles: string[]): string => {
  return `Added ${successfulFiles.length} files.`;
};

const getSuccessMessageManyDuplicate = (
  successfulElements: string[],
  duplicateElements: string[]
): string => {
  return `Added ${successfulElements.length} files(s); ${duplicateElements.length} were previously uploaded.`;
};

const getSuccessMessageSingleDuplicate = (
  successfulElements: string[],
  duplicateElement: string
): string => {
  return `Added ${successfulElements.length} files(s). ${duplicateElement} was previously uploaded.`;
};

const notifyFileUploadResponse = (
  fileUploadResponse: FileUploadResponse
): void => {
  const { successfulUploads, failedUploads, duplicateUploads } =
    fileUploadResponse;

  notifyBatchResponse(
    successfulUploads,
    duplicateUploads,
    failedUploads,
    getAllFailedMessage,
    getPartialSuccessMessage,
    getSingleSuccessMessage,
    getFewSuccessMessage,
    getManySuccessMessage,
    getSuccessMessageManyDuplicate,
    getSuccessMessageSingleDuplicate
  );
};

const concatAllSuccessfulUploads = (
  successfulUploads: string[],
  duplicateUploads: string[]
): string[] => {
  let allSuccessfulUploads = [];
  if (successfulUploads) {
    allSuccessfulUploads = [...allSuccessfulUploads, ...successfulUploads];
  }
  if (duplicateUploads) {
    allSuccessfulUploads = [...allSuccessfulUploads, ...duplicateUploads];
  }
  return allSuccessfulUploads;
};

export function ObservableFileUploader({
  onUploadStart,
  onUploadComplete,
}: ObservableFileUploaderProps): JSX.Element {
  const axiosContext = React.useContext(AxiosContext);

  const handleUploadResponse = React.useCallback(
    (
      successfulUploads?: string[],
      duplicateUploads?: string[],
      failedUploads?: FileError[]
    ) => {
      notifyFileUploadResponse({
        successfulUploads,
        duplicateUploads,
        failedUploads,
      });
    },
    []
  );

  const handleDrop = React.useCallback(
    (acceptedFiles: File[], rejectedFiles: File[]) => {
      if (rejectedFiles && rejectedFiles.length > 1) {
        notify.negative("Only one file upload is currently supported");
      } else {
        // handle file upload...
        const postToken = axios.CancelToken.source();

        const formData = new FormData();
        const uploadedFilePaths: FilePath[] = acceptedFiles.map((file) => {
          const filePath: FilePath = {
            path: getParentPath(file),
            file: {
              id: createUUID(),
              name: file.name,
            },
          };
          return filePath;
        });

        const uploadedFiles = uploadedFilePaths.map((path) => path.file);
        // To add a delay before file uploading is complete (to simulate large files), add property:
        // uploadDelayMillis: 5000,
        const uploadManifest: FileUploadManifest = {
          fileInfo: uploadedFiles,
          uploadDelayMillis: 5000,
        };
        formData.append("fileInfo", JSON.stringify(uploadManifest));
        acceptedFiles.forEach((file, index) => {
          formData.append(`${index}`, file, file.name);
        });

        onUploadStart(postToken, uploadedFilePaths);

        const uploadPromise = axiosContext.post("/upload", formData, {
          cancelToken: postToken.token,
        });
        uploadPromise
          .then((response) => {
            const { successfulUploads, duplicateUploads, failedUploads } =
              response.data as FileUploadResponse;

            handleUploadResponse(
              successfulUploads,
              duplicateUploads,
              failedUploads
            );

            const allSuccessfulUploads = concatAllSuccessfulUploads(
              successfulUploads,
              duplicateUploads
            );
            const successfulFiles = matchByName(
              allSuccessfulUploads,
              uploadedFiles
            );
            const failedFiles = matchByName(
              failedUploads?.map((fileError) => fileError.fileName),
              uploadedFiles
            );
            onUploadComplete(successfulFiles, failedFiles);
          })
          .catch((reason) => {
            if (axios.isCancel(reason)) {
              notify.info(reason?.message);
            } else {
              notify.warning(reason?.message);
            }
            onUploadComplete([], uploadedFiles);
          });
      }
    },
    [axiosContext, onUploadStart, onUploadComplete, handleUploadResponse]
  );

  const [errorMessage] = React.useState("");
  return (
    <FileUploader
      multiple={true}
      onDrop={handleDrop}
      errorMessage={errorMessage}
    />
  );
}
